import React from 'react';
import Navbar from "./components/Navbar";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Home from './pages/Home';
import ChessGame from './pages/Chess';

export default function App() {
  return (
    <>
      <Router>
        <Navbar /> {/* The Routes decides which element to show based on the current URL.*/}
        <Routes>
          <Route path='*' element={<Home />}></Route>
          <Route path='/chess' element={<ChessGame />}></Route>
        </Routes>
      </Router>
    </>
  );
}