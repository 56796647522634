import { useState } from "react";
import { Chess } from "chess.js";
import { Chessboard } from "react-chessboard";
import { cloneDeep } from "lodash";

export default function ChessGame() {
  const [game, setGame] = useState(new Chess());

  function makeAMove(move) {
    const result = game.move(move);
    const copy = cloneDeep(game);
    setGame(copy);
    return result; // null if the move was illegal, the move object if the move was legal
  }

  function makeRandomMove() {
    const possibleMoves = game.moves();
    if (game.isGameOver() || game.isDraw() || possibleMoves.length === 0){
      console.log("game over!");
      return; // exit if the game is over
    }
    const randomIndex = Math.floor(Math.random() * possibleMoves.length);
    makeAMove(possibleMoves[randomIndex]);
  }

  function doAiTurn() {
    makeRandomMove();
  }

  function canPromote(target, piece){
    if(target[1] === "8" && piece === "wP") return true;
    else if(target[1] === "0" && piece === "bP") return true;
    else return false
  }

  function onDrop(sourceSquare, targetSquare, piece) {
    const move = makeAMove({
      from: sourceSquare,
      to: targetSquare,
      promotion: canPromote(targetSquare, piece) ? 'q' : undefined // always promote to a queen for example simplicity
    });

    // illegal move
    if (move === null) return false;

    setTimeout(doAiTurn, 200);

    return true;
  }

  return <Chessboard position={game.fen()} onPieceDrop={onDrop} />;
}